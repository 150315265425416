<template>
  <q-dialog
    v-model="dialog"
    class="be-filters-dialog"
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
    persistent
    @shake="$emit('closePopUp')"
  >
    <q-card class="be-dialog-card">
      <q-btn
        flat
        icon="app:close"
        class="be-btn-round be-icon-12"
        style="position: absolute; top: 20px; right: 20px; z-index: 9000"
        @click="$emit('closePopUp')"
      />
      <q-card-section class="be-main-lr">
        <div class="be-filters-title" style="margin-top: 40px" v-html="$t('filters.select-allergens')"></div>
        <div class="be-filters-info">
          <q-icon name="app:about" class="be-icon-green be-icon-18" />
          <div v-html="$t('filters.info-allergens')"></div>
        </div>
        <div class="row q-col-gutter-md q-mb-lg">
          <div v-for="(id, index) in 14" :key="index" class="col-6">
            <div
              :class="{ 'be-filter': true, 'be-filter-active': form.allergens.indexOf(id) !== -1 }"
              @click="allergen(id)"
            >
              <q-icon :name="'app:allergen-' + id" />
              <div class="be-filter-name" v-html="$t('filters.allergens.' + id)"></div>
            </div>
          </div>
        </div>
        <q-btn class="be-btn-l bg-black full-width" :label="$t('buttons.confirm')" @click="confirmFilters" />
        <div class="text-center full-width q-mt-md q-mb-md">
          <div class="be-link" @click="resetFilters">{{ $t('filters.reset') }}</div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'SearchFilter',
  emits: ['closePopUp', 'updateFilters'],
  data() {
    return {
      dialog: true,
      form: {
        allergens: []
      }
    }
  },
  mounted() {
    if (this.$q.localStorage.has('search_filters')) {
      this.form = this.$q.localStorage.getItem('search_filters')
    }
  },
  methods: {
    allergen(id) {
      const index = this.form.allergens.indexOf(id)
      if (index !== -1) {
        this.form.allergens.splice(index, 1)
      } else {
        this.form.allergens.push(id)
      }
    },
    confirmFilters() {
      this.$q.localStorage.set('search_filters', this.form)
      this.$emit('updateFilters', this.form)
    },
    resetFilters() {
      this.form.allergens = []
      this.$q.localStorage.set('search_filters', this.form)
      this.$emit('updateFilters', this.form)
    }
  }
}
</script>
